/** @format */

import React, { useContext, useEffect, useState } from "react";
import {
	Link,
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../context/Store";
import {
	deletePlantArrival,
	getArrivals,
	submitArrivals,
} from "../../../api/activities";
import NodataFound from "../../includes/nodata/NodataFound";

const ArrivalList = () => {
	const [modal, setModal] = useState(false);
	const [getData, setData] = useState([]);
	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedArrival, setSelectedArrival] = useState("");
	const [avgRate, setAvgRate] = useState("");
	const [milkQuantity, setMilkQuantity] = useState("Litre");
	const location = useLocation();
	const navigate = useNavigate();
	const { id } = useParams();
	const {
		dispatch,
		state: { user_data },
	} = useContext(Context);
	const token = user_data.access_token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	useEffect(() => {
		dispatch({
			type: "UPDATE_USER_DATA",
			user_data: {
				active_menu: "arrivals",
			},
		});
	}, []);

	useEffect(() => {
		getArrivals(config, id)
			.then((res) => {
				if (res.app_data.StatusCode == 6000) {
					setData(res.app_data.data);
					setAvgRate(res.app_data.average_rate);
				} else {
					setData(res.app_data.data);
				}
			})
			.catch((err) => {});
	}, [user_data?.active_menu, selectedArrival]);
	const submitHandler = () => {
		setModal(true);
	};
	const cancelHandler = () => {
		setModal(false);
	};
	const confirmSubmit = () => {
		submitArrivals(config)
			.then((res) => {
				if (res.app_data.StatusCode == 6000) {
					navigate("/milk-report");
					// window.location.reload();
				} else {
				}
			})
			.catch((error) => {});
	};
	const cancelHandlerDelete = () => {
		setDeleteModal(false);
	};
	const confirmDelete = (id) => {
		deletePlantArrival(config, id)
			.then((res) => {
				if (res.app_data.StatusCode == 6000) {
					// navigate("/milk-report");
					// window.location.reload();
					setDeleteModal(false);
					setSelectedArrival("");
				}
			})
			.catch((error) => {});
	};
	return (
		<>
			<TotalContainer>
				<CoverTop>
					<Top>
						<h3>Arrivals</h3>
						<h4>
							Average Rate - <span>{avgRate?.avg_rate}</span>{" "}
						</h4>
						<Button to="/add-arrivals">Add Arrivals</Button>
					</Top>
					<CoverBottom>
						<Bottom>
							<TableHead>
								<TableItem className="society-index">Sl No.</TableItem>
								{/* <TableItem>ID</TableItem> */}
								<TableItem>Society name</TableItem>
								<TableItem>No of cans</TableItem>
								<TableItem>
									Total milk
									<div className="toggle-button">
										<span
											className={`L ${milkQuantity === "Litre" && "active"}`}
											onClick={() => setMilkQuantity("Litre")}
										>
											Litre
										</span>
										<span
											className={`Kg ${
												milkQuantity === "Kilogram" && "active"
											}`}
											onClick={() => setMilkQuantity("Kilogram")}
										>
											Kg
										</span>
									</div>
								</TableItem>
								<TableItem>Damaged Milk</TableItem>
								<TableItem className="milk-value">CLR</TableItem>
								<TableItem className="milk-value">FAT</TableItem>
								<TableItem className="milk-value">SNF</TableItem>
								<TableItem>Price(L)</TableItem>
								<TableItem>Total price</TableItem>
								<TableItem className="action">Action</TableItem>
							</TableHead>
							<TableBody>
								{getData?.length == 0 ? (
									<NodataFound />
								) : (
									getData.map((item, index) => (
										<TableContainer key={index}>
											<TableItems className="society-index">
												{index + 1}{" "}
											</TableItems>
											{/* <TableItems 
                                                title={item.scoiety_id}
                                                className="society-id"
                                            >
                                                {item.scoiety_id}{" "}
                                            </TableItems> */}
											<TableItems
												className="society-name"
												title={item.scoiety_name}
											>
												{item.society_name}
											</TableItems>
											<TableItems>{item.number_of_cans}</TableItems>
											<TableItems>
												{milkQuantity === "Litre"
													? item.total_milk
													: item.total_milk_in_kg}
											</TableItems>
											<TableItems>{item.damaged_milk_quantity}</TableItems>
											<TableItems className="milk-value">
												{item.clr_value}
											</TableItems>
											<TableItems className="milk-value">
												{item.fat_value}
											</TableItems>
											<TableItems className="milk-value">
												{item.snf_value}
											</TableItems>
											<TableItems>{item.liter_price}</TableItems>
											<TableItems className="total">
												{item.total_price}
											</TableItems>
											<TableItems className="total action">
												<i
													class="ri-file-edit-fill"
													onClick={() => navigate(`/edit-arrivals/${item.id}`)}
												></i>
												<i
													class="ri-delete-bin-6-line"
													style={{
														color: "red",
														marginLeft: "10px",
													}}
													onClick={() => {
														setSelectedArrival(item.id);
														setDeleteModal(true);
													}}
												></i>
											</TableItems>
										</TableContainer>
									))
								)}
							</TableBody>
						</Bottom>
					</CoverBottom>
				</CoverTop>
				{getData?.length !== 0 && (
					<CoverButton>
						<ButtonNew onClick={() => submitHandler()}>Submit Data</ButtonNew>
					</CoverButton>
				)}
			</TotalContainer>
			{modal && (
				<Modal>
					<ModalContainer>
						<p>
							Make sure to include the relevant information before confirming
						</p>
						<ButtonContainer>
							<ButtonNew onClick={() => cancelHandler()}>Cancel</ButtonNew>
							<ButtonNew onClick={() => confirmSubmit()} className="confirm">
								Confirm
							</ButtonNew>
						</ButtonContainer>
					</ModalContainer>
				</Modal>
			)}
			{deleteModal && (
				<Modal>
					<ModalContainer>
						<p>Are you sure you want to delete this entry?</p>
						<ButtonContainer>
							<ButtonNew onClick={() => cancelHandlerDelete()}>
								Cancel
							</ButtonNew>
							<ButtonNew
								onClick={() => confirmDelete(selectedArrival)}
								className="confirm"
							>
								Confirm
							</ButtonNew>
						</ButtonContainer>
					</ModalContainer>
				</Modal>
			)}
		</>
	);
};

export default ArrivalList;

const CoverBottom = styled.div`
	overflow: scroll;
`;
const CoverTop = styled.div``;
const CoverButton = styled.div`
	margin: 15px 20px;
`;
const TotalContainer = styled.div`
	margin-top: 30px;
	padding: 0px 15px;
	/* height: calc(100vh - 110px); */
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;
const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	span {
		color: #2382d4;
		font-family: "inter_bold";
	}
	h3 {
		font-size: 28px;
	}
`;
const Button = styled(Link)`
	background: #2382d4;
	width: 150px;
	height: 40px;
	border-radius: 8px;
	cursor: pointer;
	text-decoration: none;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Bottom = styled.div`
	padding-top: 15px;
	@media (max-width: 1080px) {
		min-width: 1500px;
		overflow: scroll;
	}
`;
const TableHead = styled.div`
	height: 70px;
	background: #fff;
	padding: 0px 15px;
	display: flex;
	align-items: center;
`;
const TableBody = styled.div`
	height: calc(100vh - 300px);
	overflow: scroll;
`;
const Icon = styled.div``;
const TableItem = styled.div`
	width: 20%;
	color: #747474;
	font-size: 18px;
	&.action {
		width: 5%;
	}
	&.society-index {
		width: 5%;
	}
	&.milk-value {
		width: 10%;
	}

	.toggle-button {
		display: flex;
		gap: 7px;
		margin-top: 5px;
	}

	span {
		font-size: 14px;
		font-weight: normal;
		padding: 0 5px;
		border: 0.4px solid #747474;
		border-radius: 3px;
		cursor: pointer;

		&.active {
			background-color: #2382d4;
			color: #ffffff;
		}
	}
`;
const TableItems = styled.div`
	width: 20%;
	color: #000000;
	font-size: 18px;
	&.total {
		color: #2382d4;
		cursor: pointer;
	}
	&.action {
		width: 5%;
	}
	&.society-index {
		width: 5%;
	}
	&.milk-value {
		width: 10%;
	}
	&.society-id {
		text-overflow: ellipsis;
		overflow: hidden;
		margin-right: 5px;
		/* width: 10%; */
	}
	&.society-name {
		text-overflow: ellipsis;
		overflow: hidden;
		margin-right: 5px;
		/* width: 10%; */
	}
`;
const TableContainer = styled.div`
	height: 70px;
	background: #fff;
	padding: 0px 15px;
	display: flex;
	align-items: center;
	margin-top: 15px;
`;
const Modal = styled.div`
	width: 100%;
	height: 100vh;
	background: #00000070;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ModalContainer = styled.div`
	width: 45%;
	padding: 60px;
	background: #fff;
	border-radius: 10px;
	text-align: center;
	p {
		margin-bottom: 20px;
		font-weight: 400;
		color: #0a0a0a;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const ButtonNew = styled.button`
	background: #2382d4;
	width: 150px;
	height: 40px;
	border-radius: 8px;
	color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	margin: 0 auto;
	&.confirm {
		background-color: #23d473;
	}
`;
