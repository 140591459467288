/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import api from "../../../api";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
	getDDMMYY,
	getDateStr,
	getYYMMDD,
} from "../../../utils/functions/function";
import { Context } from "../../../context/Store";
import ReactPaginate from "react-paginate";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";

const MilkReport = () => {
	const [datas, setDatas] = useState([]);
	const [filterModal, setFilterModal] = useState(false);
	const [filter, setFilter] = useState("");
	const [time, setTime] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEnddate] = useState("");
	const [showDateInput, setShowDateInput] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isPrintLoading, setIsPrintLoading] = useState(false);
	const [avgRate, setAvgRate] = useState("");
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const currentDate = new Date();

	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage + 1);
	}

	const {
		dispatch,
		state: { user_data },
	} = useContext(Context);

	const accessToken = user_data.access_token;
	useEffect(() => {
		dispatch({
			type: "UPDATE_USER_DATA",
			user_data: {
				active_menu: "milk-report",
			},
		});
	}, []);
	useEffect(() => {
		setIsLoading(true);
		api
			.get(
				`activities/plant-milk-report/?time=${time}&start_date=${getYYMMDD(
					startDate
				)}&end_date=${getYYMMDD(endDate)}`,
				{
					params: {
						page: currentPage,
					},
				}
			)
			.then((res) => {
				console.log(res);

				setDatas(res.app_data.data);
				setAvgRate(res.app_data.average_rate);
				setPagination(res.app_data.pagination_data);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
		// }
	}, [filter, time, startDate, endDate, currentPage]);
	const handleFilterChange = (event) => {
		setFilter(event.target.value);
	};
	const toggleDateInput = () => {
		setShowDateInput(!showDateInput);
	};
	const customStyles = {
		width: "100px",
		height: "30px",
		borderRadius: "8px",
		// Add any other custom styles here if needed
	};
	const CustomInput = ({ value, onClick }) => (
		<input
			type="text"
			value={value}
			onClick={onClick}
			style={{ width: "100px", height: "30px" }}
		/>
	);
	const handleDownload = async () => {
		setIsPrintLoading(true);
		try {
			let apiUrl = `https://api.jeevamilk.com/api/v1/activities/print-plant-arrival-report/`;

			if (startDate && endDate) {
				apiUrl = `https://api.jeevamilk.com/api/v1/activities/print-plant-arrival-report/?start_date=${getYYMMDD(
					startDate
				)}&end_date=${getYYMMDD(endDate)}`;
			}
			const response = await fetch(apiUrl, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					"Content-Type": "application/json",
				},
			});
			setIsPrintLoading(false);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
				setIsPrintLoading(false);
			}

			// Extract the blob from the response
			const blob = await response.blob();

			// Create a temporary URL for the Blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement("a");

			// Set the href attribute of the link to the URL of the Blob
			link.href = url;

			// Set the download attribute with the desired file name
			link.download = "milk-report";

			// Append the link to the document
			document.body.appendChild(link);

			// Trigger a click on the link to start the download
			link.click();

			// Remove the link from the document
			document.body.removeChild(link);

			// Revoke the URL to release the resources
			URL.revokeObjectURL(url);
		} catch (error) {
			setIsPrintLoading(false);
		}
	};

	let counter = 1;

	return (
		<TotalContainer>
			<Top>
				<h3>Milk Report</h3>
				<h4>
					Average Rate - <span>₹{Number(avgRate || 0).toFixed(2) || 0}</span>
				</h4>
				<TopRight>
					{!startDate && !endDate && (
						<DateContainer>{getDateStr(currentDate)}</DateContainer>
					)}
					<ButContainer>From</ButContainer>
					{/* <CalenderDiv onClick={toggleDateInput}>
            08 Jan, 2023
          </CalenderDiv> */}
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						required
						dateFormat="dd/MM/yyyy"
						customInput={<CustomInput />}
						style={customStyles}
					/>
					{/* {showDateInput && <input type="date" />} */}
					<ButContainer>To</ButContainer>
					<DatePicker
						selected={endDate}
						onChange={(date) => setEnddate(date)}
						required
						minDate={startDate}
						dateFormat="dd/MM/yyyy"
						customInput={<CustomInput />}
						style={customStyles}
					/>
					<TimeSect>
						<SelectButton
							className={`left ${time === "" && "active"}`}
							onClick={() => setTime("")}
						>
							All
						</SelectButton>
						<SelectButton
							className={time === "am" && "active"}
							onClick={() => setTime("am")}
						>
							AM
						</SelectButton>
						<SelectButton
							className={`right ${time === "pm" && "active"}`}
							onClick={() => setTime("pm")}
						>
							PM
						</SelectButton>
					</TimeSect>
					{/* <FilterButton onClick={() => setFilterModal((prev) => !prev)}>
            Filter
          </FilterButton> */}

					<FilterButton onClick={() => handleDownload()}>
						{isPrintLoading ? "Loading..." : "Print"}
					</FilterButton>
					<FilterContainer filterModal={filterModal}>
						<FilterTop>Filter</FilterTop>
						<FilterBottom>
							<FilterItem>
								<input
									type="radio"
									name="filter"
									value=""
									onChange={handleFilterChange}
								/>
								<label for="normal">Normal</label>
							</FilterItem>
							<FilterItem>
								<input
									type="radio"
									name="filter"
									value="route"
									onChange={handleFilterChange}
								/>
								<label for="normal">Route wise</label>
							</FilterItem>
							<FilterItem>
								<input
									type="radio"
									name="filter"
									value="society"
									onChange={handleFilterChange}
								/>
								<label for="normal">Society wise</label>
							</FilterItem>
							<FilterButtons>
								<FilterApply>Apply Filter</FilterApply>
								<FilterApply>Reset</FilterApply>
							</FilterButtons>
						</FilterBottom>
					</FilterContainer>
				</TopRight>
			</Top>
			<CoverBottom>
				<Bottom>
					<TableHead>
						<TableItem className="num">SI No.</TableItem>
						<TableItem className="date">Date</TableItem>
						<TableItem>Society</TableItem>
						<TableItem className="time">Time</TableItem>
						<TableItem>Can No</TableItem>
						<TableItem>Qty/kg</TableItem>
						<TableItem>CLR</TableItem>
						<TableItem>FAT</TableItem>
						<TableItem>SNF</TableItem>
						<TableItem>Bonus</TableItem>
						<TableItem>Net Rate</TableItem>
						<TableItem>Qty/Ltr</TableItem>
						<TableItem>Amount</TableItem>
						<TableItem>Damage</TableItem>
					</TableHead>
					<BottomTableRow>
						{
							// filter &&
							isLoading ? (
								<Loader />
							) : datas?.length > 0 ? (
								datas?.map((item, index) => (
									<>
										<FilterHead>
											<h5>
												{filter} Route: {item.route}
											</h5>
										</FilterHead>
										{item?.data?.map((soc, i) => (
											<TableBottom>
												<TableBottomItem>{counter++}</TableBottomItem>
												<TableBottomItem className="date">
													{soc?.date}
												</TableBottomItem>
												<TableBottomItem>{soc.society}</TableBottomItem>
												<TableBottomItem>
													{soc?.time_status?.toUpperCase?.()}
												</TableBottomItem>
												<TableBottomItem>{soc?.can_no}</TableBottomItem>
												<TableBottomItem>
													{soc?.quantity_in_kg?.toFixed(2)}
												</TableBottomItem>
												<TableBottomItem>{soc?.clr}</TableBottomItem>
												<TableBottomItem>{soc?.fat}</TableBottomItem>
												<TableBottomItem>{soc?.snf}</TableBottomItem>
												<TableBottomItem>{soc?.bonus}</TableBottomItem>
												<TableBottomItem>{soc?.net_rate}</TableBottomItem>
												<TableBottomItem>{soc?.quantity_in_l}</TableBottomItem>
												<TableBottomItem>{soc?.amount}</TableBottomItem>
												<TableBottomItem>
													{soc?.damaged_milk_quantity}
												</TableBottomItem>
											</TableBottom>
										))}
									</>
								))
							) : (
								<NodataFound />
							)
						}
					</BottomTableRow>

					{/* {
        !filter &&
          datas?.map((soc, i) => (
            <TableBottom>
              <TableBottomItem>{i + 1}</TableBottomItem>
              <TableBottomItem>{soc.date}</TableBottomItem>
              <TableBottomItem>{soc.created_at}</TableBottomItem>
              <TableBottomItem>{soc.can_no}</TableBottomItem>
              <TableBottomItem>
                {soc.quantity_in_kg?.toFixed(2)}
              </TableBottomItem>
              <TableBottomItem>{soc.clr}</TableBottomItem>
              <TableBottomItem>{soc.fat}</TableBottomItem>
              <TableBottomItem>{soc.snf}</TableBottomItem>
              <TableBottomItem>{soc.bonus}</TableBottomItem>
              <TableBottomItem>{soc.net_rate}</TableBottomItem>
              <TableBottomItem>{soc.quantity_in_l}</TableBottomItem>
              <TableBottomItem>{soc.amount}</TableBottomItem>
              <TableBottomItem>{soc.damaged_milk_quantity}</TableBottomItem>
            </TableBottom>
          ))} */}
				</Bottom>
			</CoverBottom>
			{pagination && pagination?.total_pages > 1 && datas?.length > 0 && (
				<PaginationContainer>
					<PaginationText>
						Showing Milk Report : {pagination?.first_item} -{" "}
						{pagination?.last_item} of {pagination?.total_items} Reports
					</PaginationText>
					<ReactPaginate
						previousLabel={"<"}
						nextLabel={">"}
						pageCount={pagination?.total_pages}
						onPageChange={handlePageClick}
						containerClassName={"pagination"}
						previousLinkClassName={"pagination__link"}
						nextLinkClassName={"pagination__link"}
						disabledClassName={"pagination__link--disabled"}
						activeClassName={"pagination__link--active"}
					/>
				</PaginationContainer>
			)}
		</TotalContainer>
	);
};

export default MilkReport;

const TotalContainer = styled.div`
	margin-top: 30px;
	padding: 0px 15px;
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	h3 {
		font-size: 28px;
	}
	flex-wrap: wrap;
	gap: 10px;
	span {
		color: #2382d4;
		font-family: "inter_bold";
	}
`;
const TopRight = styled.div`
	display: flex;
	gap: 9px;
	/* width: 55%; */
	justify-content: flex-end;
	align-items: center;
	@media (max-width: 1080px) {
		min-width: 610px;
		overflow: scroll;
	}
`;
const DateContainer = styled.div`
	color: #747474;
	font-family: "inter_regular";
	width: 100%;
	text-align: end;
`;
const ButContainer = styled.div`
	padding: 5px 8px;
	background: #2382d4;
	border-radius: 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
`;
const CoverBottom = styled.div`
	overflow: scroll;
`;
const TimeSect = styled.div`
	border-radius: 8px;
	/* overflow:hidden; */
	/* height:25px; */
	display: flex;
	gap: 3px;
`;
const SelectButton = styled.div`
	padding: 5px 8px;
	/* background:#fff; */
	border: 0.5px solid #7fadd4;
	/* background:#2382D4; */
	/* height:40px; */
	/* border-radius:8px; */
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	color: #000;
	&.left {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	&.active {
		background: #2382d4;
		color: #fff;
	}
	&.right {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
`;
const FilterButton = styled.button`
	border: none;
	outline: none;
	border: 1px solid #2382d4;
	padding: 5px 12px;
	cursor: pointer;
	border-radius: 8px;
	position: relative;
`;
const Bottom = styled.div`
	margin-top: 20px;
	background: #fff;
	/* padding:20px; */
	@media (max-width: 1080px) {
		min-width: 1280px;
		overflow: scroll;
	}
`;
const TableHead = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
`;
const BottomTableRow = styled.div`
	height: calc(100vh - 250px);
	overflow: scroll;
`;
const TableItem = styled.div`
	width: 6%;
	text-align: center;
	color: #747474;
	font-weight: 400;
	/* background:green; */
	&.num {
		/* width:5%; */
		/* width:8%; */
	}
	&.time {
		/* width:5%; */
	}
`;
const TableBottom = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	/* background:red; */
`;
const TableBottomItem = styled.div`
	width: 6%;
	text-align: center;
	font-size: 15px;
	/* background:blue; */
	&.date {
		font-size: 12px;
		font-family: "inter_bold";
		white-space: nowrap;
	}
`;
const FilterHead = styled.div`
	width: 100%;
	height: 30px;
	background: #00000033;
	padding: 20px;
	display: flex;
	align-items: center;
	h3: {
		font-size: 18px;
	}
`;
const FilterContainer = styled.div`
	position: absolute;
	width: 300px;
	/* height:500px; */
	background: #fff;
	z-index: 10;
	right: 50px;
	top: 150px;
	padding: 20px;
	transform: ${({ filterModal }) => (filterModal ? "scale(1)" : "scale(0)")};
	transition: 0.4s ease;
	transform-origin: top right;
`;
const FilterTop = styled.div`
	font-size: 33px;
	color: #2382d4;
	text-align: center;
`;
const FilterBottom = styled.div``;
const FilterItem = styled.div`
	display: flex;
	gap: 5px;
	padding: 10px 0px;
`;
const FilterButtons = styled.div`
	display: flex;
	justify-content: space-between;
`;
const FilterApply = styled.button`
	background: #2382d4;
	border: none;
	outline: none;
	width: 120px;
	height: 40px;
	radius: 4px;
	color: #fff;
`;
const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0 15px;
	@media all and (max-width: 1380px) {
		padding: 15px 0px;
		margin-right: 40px;
	}
	@media all and (max-width: 1280px) {
		margin-right: 20px;
		padding: 20px 0px;
	}
	@media all and (max-width: 980px) {
		margin-right: 10px;
	}
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
`;
const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;
