import React, { useContext, useEffect, useState } from "react";
import { add } from "../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";

import styled from "styled-components";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import api from "../../../api";
import Swal from "sweetalert2";

function Insurance() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("id");

  const [getData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(true);
  const [active, setActive] = useState("");
  const [getInsurnceID, setInsuranceID] = useState("");

  const navigate = useNavigate();
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  //get accesstoken from store
  const token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Insurance",
      },
    });
  }, []);

  //fInsurance listing
  useEffect(() => {
    setIsLoading(true);
    let url = 'activities/list-plant-insurance/';
    if(active === "True"){
      url = `activities/list-plant-insurance/?insurance_type=True`;
    }
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setData(res.app_data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setData([]);
        }
      });
  }, [active, render]);

  const confirmDelete = (e, item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDelete(e, item);
      }
    });
  };
  const confirmCancel = (e, item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to cancel Insurance?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        cancelInsurance(e, item);
      }
    });
  };
  const cancelInsurance = (e,item)=>{
    setRender(true)
    e.preventDefault();

    api
      .post(`activities/cancel-plant-insurance/${item.id}/`, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setRender(false);
        } else {
        }
      });
  }
  const handleDelete = (e, item) => {
    e.preventDefault();

    api
      .post(`activities/delete-plant-insurance/${item.id}/`, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setRender(false);
        } else {
        }
      });
  };
  return (
    <Container>
      <Top>
        <h3>Insurance</h3>
        <Button onClick={() => navigate("/add-insurance")}>
          <img src={add} alt="Add Insurance" />
          Add Insurance
        </Button>
      </Top>

      <Tabs>
        <Tab
          to=""
          className={active === "" && "active"}
          onClick={() => setActive("")}
        >
          All
        </Tab>
        {/* <Tab
         to=""
        onClick={() => handleTabClick("retagged")}
        className={active === "retagged" && "active"}
      >
        Retagged
      </Tab> */}
        <Tab
          to=""
          className={active === "True" && "active"}
          onClick={() => setActive("True")}
        >
          Cancelled
        </Tab>
      </Tabs>
      <div className="parent-scroll">
        <Bottom className="scroll">
          <TableHead>
            <TableItem className="bold">Sl No.</TableItem>
            <TableItem className="bold">Insurance ID</TableItem>
            <TableItem className="bold">Farmer name</TableItem>
            <TableItem className="bold">Tag No</TableItem>
            <TableItem className="bold">Society Name</TableItem>
            <TableItem className="bold">Date of Insurance</TableItem>
            <TableItem className="bold">Action</TableItem>
            <TableItem className="bold"></TableItem>
          </TableHead>
          <CoverContainer>
            {isLoading ? (
              <Loader />
            ) : getData.length > 0 ? (
              getData.map((item, index) => (
                <TableContent
                  key={index}
                  onClick={() => setInsuranceID(item.id)}
                >
                  <TableItem>{index + 1}</TableItem>
                  <TableItem>{item.code ? item.code : "--"}</TableItem>
                  <TableItem>{item.farmer_name}</TableItem>
                  <TableItem>{item.tag_no}</TableItem>
                  <TableItem>{item.society_name}</TableItem>
                  <TableItem>{item.date_of_insurance}</TableItem>
                  <TableItem>
                    <ViewIcon to={`/view-insurance/${item.id}`}>
                      <i class="ri-eye-line"></i>
                    </ViewIcon>
                    <EditIcon
                      onClick={() =>
                        navigate(`/edit-insurance/${item.id}`, {
                          state: {
                            insurance_id: item.insuranceId,
                          },
                        })
                      }
                    >
                      <ModeEditOutlineOutlinedIcon />
                    </EditIcon>
                    <DeleteIcon
                      onClick={(e) => {
                        confirmDelete(e, item);
                      }}
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </DeleteIcon>
                  </TableItem>
                  <TableItem className="cancel" onClick={(e)=> confirmCancel(e,item)}>Cancel</TableItem>

                </TableContent>
              ))
            ) : (
              <NodataFound />
            )}
          </CoverContainer>
        </Bottom>
      </div>
    </Container>
  );
}

export default Insurance;

const Container = styled.div`
  margin-top: 20px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Top = styled.div`
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  width: 175px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top:20px;
`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;

  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;

const Bottom = styled.div`
  padding-top: 15px;
`;
const TableHead = styled.div`
  height: 70px;
  background: #f8f8f8;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableItems = styled.div`
  width: 10%;
  color: #000;
  font-size: 18px;
`;

const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const CoverContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;

const TableContent = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EditIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewIcon = styled(Link)`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableItem = styled.div`
  color: #747474;
  font-size: 15px;
  display: flex;
  font-family: "inter_regular";
  align-items: center;
  width: 12%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  &.bold{
    font-family: "inter_medium";
  }
  &.cancel{
    font-family: "inter_medium";
    color: #ff9e9e;
    cursor: pointer;
  }
`;
